import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SchemeState } from './scheme.state';

export const schemeFeatureKey = 'scheme';
export const selectSchemeFeature = createFeatureSelector<SchemeState>(schemeFeatureKey);
export const selectAllSchemes = createSelector(
  selectSchemeFeature,
  (schemeState: SchemeState) => schemeState.schemes
);
export const selectLoadingSchemes = createSelector(
  selectSchemeFeature,
  (schemeState: SchemeState) => schemeState.loadingSchemes
);
export const selectScheme = createSelector(
  selectSchemeFeature,
  (schemeState: SchemeState) => schemeState.scheme
);
export const selectLoadingScheme = createSelector(
  selectSchemeFeature,
  (schemeState: SchemeState) => schemeState.loadingScheme
);
export const selectSchemeUpdated = createSelector(
  selectSchemeFeature,
  (schemeState: SchemeState) => schemeState.schemeUpdated
);
export const selectSchemeDeleted = createSelector(
  selectSchemeFeature,
  (schemeState: SchemeState) => schemeState.schemeDeleted
);
